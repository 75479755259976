export const allquestions = [
    {
        question: {
            question_id: 1,
            name: 'May we know your overall satisfaction level with our property?',
        },
        answer: [
            { name: "Extremely Satisfied", answer_id: 1 },
            { name: "Quite Satisfied", answer_id: 2 },
            { name: "Moderately Satisfied", answer_id: 3 },
            { name: "Slightly Satisfied", answer_id: 4 },
            { name: "Not Satisfied", answer_id: 5 }
        ]
    }
];

export const allratingquestions = [
    {
        question_id: 1,
        name: 'How would you rate the overall experience of your stay?',
        ratingValue: 0,
        tooltipArray: [
            'Terrible',
            'Poor',
            'Average',
            'Great',
            'Perfect'
        ]
    },
    {
        question_id: 2,
        name: "How would you rate your check-in experience at our property?",
        ratingValue: 0,
        tooltipArray: [
            'Terrible',
            'Poor',
            'Average',
            'Great',
            'Perfect'
        ]
    },
    {
        question_id: 3,
        name: "How would you rate the facilities & amenities of the property?",
        ratingValue: 0,
        tooltipArray: [
            'Terrible',
            'Poor',
            'Average',
            'Great',
            'Perfect'
        ]
    },
    {
        question_id: 4,
        name: "How would you rate cleanliness and condition of your room?",
        ratingValue: 0,
        tooltipArray: [
            'Terrible',
            'Poor',
            'Average',
            'Great',
            'Perfect'
        ]
    }
];

export const allTextAreaQuestions = [
    {
        question_id: 1,
        name: 'Do you have any comments, suggestions or concerns?',
        fieldName: 'textarea2'
    }
];

export const allPhoto = { 
        name: "Any photos you would like to upload?",
        required: "Optional"
    };

export const errorPageContent = { 
    title: "Jupiter",
    notfound: "NOT FOUND",
    para1: "Oops! We could not find the property you are looking for.",
    para2: "If you wish to book a room at any of our other properties, please write us at below email. We will be glad to serve you.",
    email: "emailaddress@host.com",
    phone1: "+91 9999999999",
    phone2: "+91 0000000000"
};
