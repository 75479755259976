import axios from "axios";
import { getApiHeaders } from './helper';

// Get property content from API
export const getPropertyData = async (property_id) => {
    const result = await axios
        .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/property/${property_id}`, getApiHeaders())
        .then(res => res);
    return result?.data;
}

// Get property color configurations from API
export const getPropertyConfig = async (property_id) => {
    const result = await axios
        .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/property/${property_id}/config`, getApiHeaders())
        .then(res => res);
    return result?.data;
}

// Get reservation data from API
export const getReservation = async (property_id, reservation_id) => {
    const result = await axios
        .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/property/${property_id}/reservation/${reservation_id}`, getApiHeaders())
        .then(res => res);
    return result?.data;
}

// Get review questions from API
export const getReviewQuestions = async () => {
    const result = await axios
        .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/property/reviewQuestions`, getApiHeaders())
        .then(res => res);
    return result?.data;
}

// Create review api
export const createReview = async (property_id, review_data) => {
    const result = await axios
        .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/property/${property_id}/review`, review_data, getApiHeaders(true))
        .then(res => res);
    return result?.data;
}

// Get Review api
export const getReview = async (property_id, reservation_id) => {
    const result = await axios
        .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/property/${property_id}/reservation/${reservation_id}/review`, getApiHeaders())
        .then(res => res);
    return result?.data;
}
