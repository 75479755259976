import React from 'react';
import { Container, Image, Navbar } from 'react-bootstrap';

export default function Header(props) {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#">
          <Image src={props?.propertyLogo} height={30} />
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}
