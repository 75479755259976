
export const getApiHeaders = (is_file = false) => {
  return {
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Authorization': `JPTR Key=${process.env.REACT_APP_API_KEY}, Signature=${process.env.REACT_APP_API_SIGNATURE}`,
      'Content-Type': is_file ? 'multipart/form-data' : 'application/json'
    }
  }
}

/**
 * SOURCE: https://stackoverflow.com/questions/24337317/encrypt-with-php-decrypt-with-javascript-cryptojs
 */
export const decrypt = (encrypted) => {
  const CryptoJS = require('crypto-js');
  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams;
    }
  }

  var decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_PASS_PHRASE, { format: CryptoJSAesJson }).toString(CryptoJS.enc.Utf8));
  return decrypted;
}

export const getSearchParamsTokens = (searchParams) => {
  let tokenArray = {};
  tokenArray['ct'] = searchParams.get("token1").replaceAll(' ', '+');
  tokenArray['iv'] = searchParams.get("token2").replaceAll(' ', '+');
  tokenArray['s'] = searchParams.get("token3").replaceAll(' ', '+');
  var encryptedToken = JSON.stringify(tokenArray);
  return encryptedToken;
}
