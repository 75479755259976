import { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import HotelDetails from "./hotelDetails";
import RadioButton from "./radioButton";
import StarRating from "./starRating";
import TextArea from "./textArea";
import InputImage from "./inputImage";
import { allquestions, allratingquestions, allTextAreaQuestions, allPhoto } from "../mockData/mock";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../assets/css/App.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getPropertyData, getPropertyConfig, getReservation, getReviewQuestions, createReview, getReview } from '../api'
import { decrypt, getSearchParamsTokens } from '../helper';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";

const Home = () => {
    // eslint-disable-next-line no-unused-vars
    const [questions, setQuestions] = useState(allquestions);
    // eslint-disable-next-line no-unused-vars
    const [ratingquestions, setRatingquestions] = useState(allratingquestions);
    // eslint-disable-next-line no-unused-vars
    const [textAreaQuestions, setTextAreaQuestions] = useState(allTextAreaQuestions);
    // eslint-disable-next-line no-unused-vars
    const [addPhoto, setAddPhoto] = useState(allPhoto);
    const [propertyData, setPropertyData] = useState([]);
    const [propertyConfig, setPropertyConfig] = useState([]);
    const [reservationData, setReservationData] = useState([]);
    const [noOfNights, setNoOfNights] = useState();
    const [reservationId, setReservationId] = useState();
    const [propertyImages, setPropertyImages] = useState([]);
    let { p_id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [formReviewData, setFormReviewData] = useState([]);
    const [submittedReviewData, setSubmittedReviewData] = useState([]);
    const [showFlag, setShowFlag] = useState(false);

    useEffect(() => {
        const encryptedToken = getSearchParamsTokens(searchParams);
        const getDecryptData = async (encrypted) => {
            try {
                const response = await decrypt(encrypted);
                const obj = new URLSearchParams(response);
                setReservationId(obj.get('rid'));
            } catch (error) {
                navigate("/error");
            }
        }

        getDecryptData(encryptedToken);
    }, [navigate, searchParams]);

    useEffect(() => {
        const getSubmittedReview = async (prop_id, reservation_id) => {
            try {
                const res = await getReview(prop_id, reservation_id);
                if(res){
                    setSubmittedReviewData(res);
                }
            } catch (error) {
                if (error?.response?.status !== 404) {
                    navigate("/error");
                }
            }
            setShowFlag(true);
        }

        const getReviewQuestionsData = async () => {
            try {
                const response = await getReviewQuestions();
                if(response){
                    setFormReviewData(response?.map(data => (
                        {...data, 'form_value': '', 'showvalidation': false, ...(data.field === "textarea" && {maxlength: 1000, currentlength: 0})}
                    )));
                }else{
                    setFormReviewData([]);
                }
            } catch (error) {
                navigate("/error");
            }
        }

        const getPropertyConfigData = async (prop_id) => {
            try {
                const res = await getPropertyConfig(prop_id);
                setPropertyConfig(res);
            } catch (error) {
                navigate("/error");
            }
        }

        const getPropertyApiData = async (prop_id) => {
            try {
                const response = await getPropertyData(prop_id);
                setPropertyData(response);
                document.title = 'Guest Feedback | ' + response?.name;
                response?.images?.map((each_image, index) => (
                    each_image?.type === 'Room' && setPropertyImages(oldArray => [...oldArray, each_image?.url])
                ))
            } catch (error) {
                navigate("/error");
            }
        }

        const getReservationApiData = async (prop_id, reservation_id) => {
            try {
                const res = await getReservation(prop_id, reservation_id);
                setReservationData(res);
                var days_diff = new Date(res?.checkout).getTime() - new Date(res?.checkin).getTime();
                var no_of_nights = Math.floor(days_diff / (1000 * 3600 * 24));
                setNoOfNights(no_of_nights);
            } catch (error) {
                navigate("/error");
            }
        }

        const prop_char = p_id.substring(0, 1);
        const pattern = new RegExp('^[0-9]+$');
        const prop_id = p_id.slice(1);
        if ((prop_char === 'h') && pattern.test(prop_id)) {
            if (reservationId){
                getSubmittedReview(prop_id, reservationId);
            }
            getReviewQuestionsData();
            getPropertyApiData(prop_id);
            getPropertyConfigData(prop_id);
            if (reservationId){
                getReservationApiData(prop_id, reservationId);
            }
        } else {
            navigate("/error");
        }
    }, [p_id, navigate, reservationId]);

    const formSubmit = (e) => {
        e.preventDefault();

        const getReviewSubmitted = async (prop_id, reservation_id) => {
            try {
                const res = await getReview(prop_id, reservation_id);
                if(res){
                    setSubmittedReviewData(res);
                }
            } catch (error) {
                if (error?.response?.status !== 404) {
                    navigate("/error");
                }
            }
        }

        const cloneFormReviewData = JSON.parse(JSON.stringify(formReviewData));
        cloneFormReviewData?.map((each_question, index) => (
            (each_question?.required === true && each_question?.form_value === '') ?
                cloneFormReviewData[index]['showvalidation'] = true : 
                cloneFormReviewData[index]['showvalidation'] = false
        ));
        if (cloneFormReviewData?.some(field => field?.showvalidation)) {
            setFormReviewData(cloneFormReviewData);
            return;
        }

        const formData = new FormData();

        formReviewData?.map((each_question, index) => {
            if (each_question?.field === 'file' && each_question?.form_value !== '') {
                return each_question?.form_value?.map(file => formData.append(`${each_question?.col}[]`, file));
            } else {
                return formData.append(each_question?.col, each_question?.form_value);
            }
        });

        formData.append('reservation_id', reservationId);

        const getCreatedReview = async (output) => {
            try {
                const prop_id = p_id.slice(1);
                const response = await createReview(prop_id, output);
                if (response && prop_id && reservationId) {
                    getReviewSubmitted(prop_id, reservationId);
                }
            } catch (error) {
                navigate("/error");
            }
        }
        getCreatedReview(formData);
    }

    const handleChange = (index, value) => {
        const cloneFormReviewData = JSON.parse(JSON.stringify(formReviewData));
        cloneFormReviewData[index]["form_value"] = value;
        cloneFormReviewData[index]["showvalidation"] = false;
        if (cloneFormReviewData[index]["field"] === "textarea") {
            cloneFormReviewData[index]["currentlength"] = value.length;
        }
        setFormReviewData(cloneFormReviewData);
    };

    return (
        <>
            <Header propertyLogo={propertyData?.logo} />
            {showFlag && 
            <>
                {submittedReviewData?.guest_id && submittedReviewData?.overall_exp && submittedReviewData?.checkin_exp && submittedReviewData?.facility && submittedReviewData?.cleanliness && submittedReviewData?.satisfaction 
                ? <div className="submitted-message">
                    <FaRegCheckCircle className="check-icon" size={180}/>
                    <h4>Thank you for your valuable feedback.</h4>
                    <p>We genuinely appreciate your time and effort in assisting us in our continuous pursuit of excellence.</p>
                </div> 
                : <>
                    <Container className="mt-5 mb-5">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            {propertyData?.name &&
                                <HotelDetails propertyImage={propertyImages?.[0] ? propertyImages?.[0] : (propertyData?.images?.[0] ? propertyData?.images?.[0]?.url : '')} propertyName={propertyData?.name} propertyAddress={propertyData?.location} reservationData={reservationData} noOfNights={noOfNights} />
                            }
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8}>
                            <Form className='question-div' onSubmit={formSubmit}>
                                <div>
                                    {propertyConfig?.colors?.btn?.bg && propertyConfig?.colors?.btn?.font && formReviewData?.map((each_question, index) => (
                                        <div key={index} className="mb-5">
                                            <div className='question-box'>
                                                {each_question?.field === 'rating' &&
                                                    <>
                                                        <h6>{each_question?.text}</h6>
                                                        <div className='answer'>
                                                            <StarRating
                                                                field_name={each_question?.col}
                                                                ratingValue={each_question?.form_value || 0}
                                                                tooltipArray={Object.values(each_question?.values)}
                                                                propertyConfig={propertyConfig?.colors?.btn}
                                                                onChange={handleChange}
                                                                index={index}
                                                                required={each_question?.required}
                                                                showvalidation={each_question?.showvalidation}
                                                            />
                                                        </div>
                                                    </>
                                                }

                                                {each_question?.field === 'radio' &&
                                                    <>
                                                        <h6>{each_question?.text}</h6>
                                                        <div className='answer'>
                                                            <RadioButton
                                                                field_name={each_question?.col}
                                                                id={each_question?.col} 
                                                                data={Object.entries(each_question?.values)} 
                                                                propertyConfig={propertyConfig?.colors?.btn}
                                                                value={each_question?.form_value || 0}
                                                                onChange={handleChange}
                                                                index={index}
                                                                formReviewData={formReviewData}
                                                                required={each_question?.required}
                                                                showvalidation={each_question?.showvalidation}
                                                            />
                                                        </div>
                                                    </>
                                                }

                                                {each_question?.field === 'textarea' &&
                                                    <>
                                                        <h6>{each_question?.text}</h6>
                                                        <div className='answer'>
                                                            <TextArea
                                                                field_name={each_question?.col}
                                                                id={each_question?.col}
                                                                value={each_question?.form_value}
                                                                onChange={handleChange}
                                                                index={index}
                                                                formReviewData={formReviewData}
                                                                required={each_question?.required}
                                                                showvalidation={each_question?.showvalidation}
                                                            />
                                                        </div>
                                                    </>
                                                }

                                                {each_question?.field === 'file' &&
                                                    <InputImage
                                                        field_name={each_question?.col}
                                                        id={each_question?.col} 
                                                        question_name={each_question?.text}
                                                        onChange={handleChange}
                                                        index={index}
                                                        required={each_question?.required}
                                                        showvalidation={each_question?.showvalidation}
                                                        propertyConfig={propertyConfig?.colors?.btn}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    ))}
                                    <div>
                                    {propertyConfig?.colors?.btn?.bg && propertyConfig?.colors?.btn?.font &&
                                        <Button style={{backgroundColor:propertyConfig?.colors?.btn?.bg !== '' ? propertyConfig?.colors?.btn?.bg : '', color:propertyConfig?.colors?.btn?.font !== '' ? propertyConfig?.colors?.btn?.font : ''}} className="btn btn-dark btn-submit-review" type='submit' variant="primary">Submit Review</Button>
                                    }
                                    </div>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                {propertyConfig?.colors?.btn?.bg && propertyData?.social_links?.facebook && propertyData?.social_links?.twitter && propertyData?.social_links?.instagram && propertyData?.social_links?.pinterest && 
                    <Footer propertyData={propertyData} propertyConfig={propertyConfig?.colors} />
                }
                </>
                }
            </>
            }
        </>
    )
}

export default Home
