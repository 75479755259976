import React from 'react'
import Form from 'react-bootstrap/Form';

const RadioButton = (props) => {

    return (
        <>
            <div>
                {props?.data.map(each_radio => (
                    <div className={props?.formReviewData[props?.index]['form_value'] === each_radio[0] ? 'cat active' : 'cat'} style={{backgroundColor: (props?.formReviewData[props?.index]['form_value'] === each_radio[0] && props?.propertyConfig?.bg !== '') ? props?.propertyConfig?.bg : '',borderColor: (props?.formReviewData[props?.index]['form_value'] === each_radio[0] && props?.propertyConfig?.bg !== '') ? props?.propertyConfig?.bg : '',color: (props?.formReviewData[props?.index]['form_value'] === each_radio[0] && props?.propertyConfig?.font !== '') ? props?.propertyConfig?.font : '' }} key={each_radio[0]}>
                        <label htmlFor={each_radio[0]}>{each_radio[1]}
                            <Form.Check id={each_radio[0]} type="radio" name={props?.field_name} value={each_radio[0]}
                                onChange={(e) => props.onChange(props?.index, e.target.value)} checked={props?.formReviewData[props?.index]['form_value'] === each_radio[0] ? 'checked' : ''} />
                        </label>
                    </div>
                ))}
            </div>
            {
                (props?.required && props?.showvalidation) && 
                <div>
                    <p className="validation-msg">This field is required</p>
                </div>
            }
        </>
    )
}

export default RadioButton
