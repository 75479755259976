import React from 'react';
import Card from 'react-bootstrap/Card';
import defaultHotel from '../assets/img/defaultHotel.png';

export default function HotelDetails(props) {
  // const div = useRef();

  // useLayoutEffect(() => {
  //   const divAnimate = div.current.getBoundingClientRect().top;
  //   var extra_div = document.getElementById('extra-div');
  //   const onScroll = () => {
  //     if (divAnimate < window.scrollY) {
  //       div.current.style.position = "fixed";
  //       div.current.style.top = 0;
  //       div.current.style.left = 5;
  //       // div.current.style.width = '27.2%';
  //       // div.current.style.height = '65%';
  //       // div.current.style.paddingRight = '60px';
  //       extra_div.classList.remove('d-none');
  //     } else {
  //       div.current.style.position = "relative";
  //       div.current.style.width = '35%';
  //       div.current.style.height = '100%';
  //       div.current.style.paddingRight = '60px';
  //       extra_div.classList.add('d-none');

  //     }
  //   };
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  return (
    <div className='hotel-div' style={{ width: '100%', borderRight: '1px solid #C0C0C0', paddingRight: '60px' }}>
      <Card.Title className='h1 mb-3'>
        <h2><b>Tell us, how was your stay?</b></h2>
      </Card.Title>
      <Card>
        <Card.Img src={props?.propertyImage ? props?.propertyImage : defaultHotel} alt="hotel-image" />
        <Card.Body>
          <Card.Title>{props?.propertyName}</Card.Title>
          <Card.Text>
            {props?.propertyAddress?.add}{props?.propertyAddress?.city !== "" && ', ' + props?.propertyAddress?.city}{props?.propertyAddress?.postal_code !== "" && ' ' + props?.propertyAddress?.postal_code}{props?.propertyAddress?.state !== "" && ', ' + props?.propertyAddress?.state}{props?.propertyAddress?.country !== "" && ', ' + props?.propertyAddress?.country}
          </Card.Text>
        </Card.Body>
      </Card>
      <div className='reservation-details'>
        <Card>
          <Card.Body>
            <Card.Title>Reservation Details</Card.Title>
            <div className='reservation'>
              <Card.Text>
                <b>Check-in:</b> {props?.reservationData?.checkin}
              </Card.Text>
              <Card.Text>
                <b>Check-out:</b> {props?.reservationData?.checkout}
              </Card.Text>
              <Card.Text>
                <b>Night(s):</b> {props?.noOfNights}
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
