import React from 'react';
import { Rating } from 'react-simple-star-rating';

const StarRating = (props) => {

    return (
        <>
            <Rating
                initialValue={props?.ratingValue}
                onClick={(value) => props?.onChange(props?.index, value)}
                name={props?.field_name}
                showTooltip
                tooltipArray={props?.tooltipArray}
                fillColor={props?.propertyConfig?.bg !== '' ? props?.propertyConfig?.bg : '#ffa500'}
                tooltipStyle={{
                    'color': props?.propertyConfig?.font !== '' ? props?.propertyConfig?.font : '#ffffff',
                    'backgroundColor': props?.propertyConfig?.bg !== '' ? props?.propertyConfig?.bg : '#ffa500',
                    'padding': '5px 10px',
                    'borderRadius': '50px',
                    'marginLeft': '15px',
                }}
                tooltipDefaultText='Rate Your Experience'
                size={32}
            />
            {
                (props?.required && props?.showvalidation) && 
                <div>
                    <p className="validation-msg">This field is required</p>
                </div>
            }
        </>
        
    )
  }
  
  export default StarRating
