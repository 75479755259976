import React from 'react';
import Form from 'react-bootstrap/Form';

const TextArea = (props) => {

  return (
    <>
      <div>
        <Form.Control 
          as="textarea"
          id={props?.id}
          name={props?.field_name}
          value={props?.value}
          onChange={(e) => props.onChange(props?.index, e.target.value)}
          rows="4"
          cols="80"
          placeholder='Please write here...'
          maxLength={props?.formReviewData[props?.index]['maxlength']}
        />
        <div className="textarea-count">
          <span>{props?.formReviewData[props?.index]['currentlength']}</span>
          <span>/{props?.formReviewData[props?.index]['maxlength']}</span>
        </div>
      </div>
      {
        (props?.required && props?.showvalidation) && 
          <div>
              <p className="validation-msg">This field is required</p>
          </div>
      }
    </>
  )
}

export default TextArea
