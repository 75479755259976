import React from 'react';
import ErrorImage from '../assets/img/greens.jpg';
import { errorPageContent } from "../mockData/mock";
import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FaEnvelope, FaPhoneAlt, FaFacebookSquare, FaTwitter, FaInstagram } from "react-icons/fa";

export default function Error() {

    return (
        <>
            <div className='error-page-div' style={{ backgroundImage: `url(${ErrorImage})` }}>
                <div className="errorpage-header">
                    <p>{errorPageContent.title}</p>
                </div>
                <div className="errorpage-notfound">
                    <p>{errorPageContent.notfound}</p>
                </div>
                <div className="errorpage-para">
                    <p>{errorPageContent.para1}<br />
                        {errorPageContent.para2}</p>
                </div>
                <div className="errorpage-contact-div">
                    <Nav className="errorpage-contact">
                        <Link className="errorpage-link" to={'mailto:' + errorPageContent.email}>
                            <FaEnvelope /> {errorPageContent.email}
                        </Link>
                        <Link className="errorpage-link" to={'tel:' + errorPageContent.phone1}>
                            <FaPhoneAlt /> {errorPageContent.phone1} / {errorPageContent.phone2}
                        </Link>
                    </Nav>
                </div>
                <div className="errorpage-icon-div row">
                    <Link className="errorpage-icon" to='#'>
                        <FaFacebookSquare size={30} />
                    </Link>
                    <Link className="errorpage-icon" to='#'>
                        <FaTwitter size={30} />
                    </Link>
                    <Link className="errorpage-icon" to='#'>
                        <FaInstagram size={30} />
                    </Link>
                </div>
            </div>
        </>
    )
}
