import React from 'react';
import Toast from 'react-bootstrap/Toast';

function ShowError(props) {

  return (
    <div className='toast-div'>
        <Toast onClose={() => props?.setShow(false)} show={props?.show} delay={5000} bg={'danger'} autohide>
          <Toast.Header>
            <strong className="me-auto">Warning</strong>
          </Toast.Header>
          <Toast.Body className={'text-white'}>{props?.warning}</Toast.Body>
        </Toast>
    </div>
  );
}

export default ShowError;