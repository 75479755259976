import React from 'react';
import { Container, Image, Navbar } from 'react-bootstrap';
import logo from '../assets/img/jupiter.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFacebookSquare, FaTwitter, FaInstagram, FaPinterestP } from "react-icons/fa";

export default function Footer(props) {
  return (
    <Navbar expand="lg" style={{background: `linear-gradient(0deg, ${props?.propertyConfig?.btn?.bg} 0%, rgba(255,255,255,1) 100%)` }} >
        <Container className='footer-container'>
            <Row style={{width:'100%'}}>
                <Col xs={6} sm={6} md={6} lg={6} className='footer-col-left'>
                    <div className="footer-icon-div" style={{backgroundColor: props?.propertyConfig?.btn.bg}}>
                        <Navbar.Brand href={props?.propertyData?.social_links?.facebook}>
                            <FaFacebookSquare className='footer-icon' src={props?.propertyLogo} size={17} />
                        </Navbar.Brand>
                    </div>
                    <div className="footer-icon-div" style={{backgroundColor: props?.propertyConfig?.btn.bg}}>
                        <Navbar.Brand href={props?.propertyData?.social_links?.twitter}>
                            <FaTwitter className='footer-icon' src={props?.propertyLogo} size={17} />
                        </Navbar.Brand>
                    </div>
                    <div className="footer-icon-div" style={{backgroundColor: props?.propertyConfig?.btn.bg}}>
                        <Navbar.Brand href={props?.propertyData?.social_links?.instagram}>
                            <FaInstagram className='footer-icon' src={props?.propertyLogo} size={17} />
                        </Navbar.Brand>
                    </div>
                    <div className="footer-icon-div" style={{backgroundColor: props?.propertyConfig?.btn.bg}}>
                        <Navbar.Brand href={props?.propertyData?.social_links?.pinterest}>
                            <FaPinterestP className='footer-icon' src={props?.propertyLogo} size={17} />
                        </Navbar.Brand>
                    </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} className='footer-col-right'>
                        <p className='footer-para'>Powered by </p>
                        <Navbar.Brand href='https://www.jupiterhms.com/'>
                            <Image src={logo} height={20} />
                        </Navbar.Brand>
                </Col>
            </Row>
        </Container>
    </Navbar>
  )
}
