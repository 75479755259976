import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ShowError from "./showError";
import { MdCancel } from "react-icons/md";
import {useRef} from 'react';

const imageTypeRegex = /image/gm;
const videoTypeRegex = /video/gm;

const TextArea = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [inputFiles, setInputFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [warningMsg, setWarningMsg] = useState('');
  const input_file = useRef(null);
    
    const handleInputFileClick = () => {
        input_file.current.click();
    };

    const changeHandler = (e) => {
        const { files } = e.target;
        let no_of_files = inputFiles.length + files.length;
        if(no_of_files > 10){
            setWarningMsg('Number of files selected should be less than or equal to 10');
            setShow(true);
            return;
        }
        const validFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.match(imageTypeRegex) || file.type.match(videoTypeRegex)) {
                validFiles.push(file);
            }else{
                setWarningMsg('Selected file(s) are not of valid type!');
                setShow(true);
                return;
            }
            if (file.type.match(videoTypeRegex) && file.size > 10000000) {
                setWarningMsg('Selected video file(s) should not be greater than 10 MB');
                setShow(true);
                return;
            }
        }
        if (validFiles.length) {
            setInputFiles(prev => ([...prev, ...validFiles]));
            props?.onChange(props?.index, [...inputFiles, ...validFiles]);
            return;
        }
    };

    const deleteAttachment = (idx) => {
        const array_files = [...inputFiles];
        array_files.splice(idx, 1);
        setInputFiles(array_files);
        props?.onChange(props?.index, array_files);
    }

  return (
    <div>
        <Form.Group id={props?.id} className="mb-3">
            <Form.Label className="question"><b>{props?.question_name}</b> {!props?.required && '(Optional)'}</Form.Label>
            <div className="input-file-div">
                <Button type="button" style={{backgroundColor:props?.propertyConfig?.bg !== '' ? props?.propertyConfig?.bg : '', color:props?.propertyConfig?.font !== '' ? props?.propertyConfig?.font : '', borderColor:props?.propertyConfig?.bg !== '' ? props?.propertyConfig?.bg : ''}} onClick={handleInputFileClick}> Choose File
                    <Form.Control
                            ref={input_file}
                            className='answer file-input'
                            type="file"
                            id={props?.id}
                            name={props?.field_name}
                            onChange={changeHandler}
                            accept="image/*, video/*, .mov, .avi, .wmv, .flv, .mkv" multiple />
                </Button>
                <div className='file-count'><b>Files Selected: {inputFiles.length}</b></div>
            </div>
        </Form.Group>
        {
            inputFiles?.length > 0 ?
                <div className='row' style={{ display: 'flex' }}>
                    {
                        inputFiles?.map((file, idx) => {
                            return <div className='col-md-3' style={{ width: '210px', margin: '10px 10px 0px 0px' }} key={idx}>
                                        {file?.type?.match(imageTypeRegex) 
                                        ? <div className='attachment-div'>
                                                <MdCancel onClick={() => deleteAttachment(idx)} className='remove-attachment' title='Remove' size={22}/>
                                                <Image width="200px" height="150px" src={URL.createObjectURL(file)} alt="" />
                                        </div>
                                        : <div className='attachment-div'>
                                                <MdCancel onClick={() => deleteAttachment(idx)} className='remove-attachment' title='Remove' size={22}/>
                                                <video width="200px" height="150px" alt="" controls><source src={URL.createObjectURL(file)} /></video>
                                        </div>
                                        }
                                    </div>
                        })
                    }
                </div> : null
        }
        {show === true && 
            <ShowError show={show} setShow={setShow} warning={warningMsg} />
        }
        {
            (props?.required && props?.showvalidation) && 
            <div>
                <p className="validation-msg">This field is required</p>
            </div>
        }
    </div>
  )
}

export default TextArea
